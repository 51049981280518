<template>
  <div class="dswindow">
    <div class="electrical">
      <div class="btn"></div>
      <div class="title">{{ deviceName }}</div>
      <div class="number_of_failures">昨日用电量: <span>{{quantityOfElectricity0 || ''}}</span> kW·h</div>
    </div>
  </div>
</template>

<script>
import {getElectricity} from '@/api'

export default {
  data(){
    return{
      quantityOfElectricity0:'',
      deviceName:'dasf'
    }
  },
  mounted() {
    console.log('Component mounted.');
    this.$emit('loading')
    if(this.$route.query.deviceNumber){
      this.init(this.$route.query.deviceNumber)
    }else{
      this.deviceName = '未获取到设备'
    }
  },
  methods:{
    //获取设备数据
      async init(deviceNumber){
        const form = {deviceNumber}
        getElectricity(form).then(res => {
          if(res.code === 200){
              this.quantityOfElectricity0 = res.quantityOfElectricity0
          }
        })
        
      }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 278px;
  height: 204px;
  padding-top: 20px;
  .electrical {
    position: relative;
    width: 258px;
    height: 134px;
    background: url('~@/assets/gd/ds_electricalcard.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 20px;
    color: #3acbe0;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    .btn {
      background: url('~@/assets/gd/ds_closebtn.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: -19px;
      top: -20px;
      width: 38px;
      height: 44px;
    }
    .title {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #fff;
    }
    .number_of_failures {
      line-height: 15px;
      font-size: 15px;
      margin-top: 14px;
    }
    .illustrate {
      display: flex;
      font-size: 16px;
      padding-top: 14px;
      align-items: center;
      .left {
        width: 66%;
        height: 28px;
      }
      .left:nth-of-type(1) span {
        font-size: 24px;
      }
      .right {
        width: 40%;
      }
    }
    span {
      color: #fdcc30;
      font-size: 24px;
      line-height: 17px;
    }
  }
}
</style>